import React from 'react';
import { Icon } from '@bedrockio/pages';
import { bem } from '@bedrockio/pages/utils';

import Context from './Context';

import './trigger.less';

@bem
export default class ExpandableTrigger extends React.Component {
  static contextType = Context;

  render() {
    const { children, ...rest } = this.props;
    const { open } = this.context;
    return (
      <div
        {...rest}
        tabIndex="0"
        className={this.getBlockClass()}
        onClick={this.context.onTriggerClick}>
        {children}
        <Icon name={open ? 'minus' : 'plus'} />
      </div>
    );
  }
}
