import React from 'react';

import { Collection } from '@bedrockio/pages';
import { bem } from '@bedrockio/pages/utils';

import './images.less';

@bem
export default class AppScreenImages extends React.Component {
  getStyles() {
    return {
      '--current-index': this.context.currentIndex,
    };
  }

  render() {
    return (
      <Collection
        name="app-screen-images"
        style={this.getStyles()}
        limit={this.context.length}
        className={this.getBlockClass()}
        fields={{
          image: 'image@350w',
        }}
        renderItem={(item, data) => {
          const isCurrent = data.index === this.context.currentIndex;
          const { image } = item;
          return (
            <div
              className={this.getElementClass(
                'image',
                isCurrent ? 'current' : null,
              )}>
              {image}
            </div>
          );
        }}
      />
    );
  }
}
