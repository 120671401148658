import React from 'react';

import Images from './Images';
import Descriptions from './Descriptions';

const Context = React.createContext();

Images.contextType = Context;
Descriptions.contextType = Context;

export default class AppScreen extends React.Component {
  static Images = Images;
  static Descriptions = Descriptions;

  constructor(props) {
    super(props);
    this.state = {
      currentIndex: 0,
    };
  }

  next = () => {
    const { length } = this.props;
    this.setState({
      currentIndex: Math.min(length - 1, this.state.currentIndex + 1),
    });
  };

  prev = () => {
    this.setState({
      currentIndex: Math.max(0, this.state.currentIndex - 1),
    });
  };

  prevDisabled = () => {
    return this.state.currentIndex === 0;
  };

  nextDisabled = () => {
    const { length } = this.props;
    return this.state.currentIndex === length - 1;
  };

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          next: this.next,
          prev: this.prev,
          prevDisabled: this.prevDisabled,
          nextDisabled: this.nextDisabled,
          length: this.props.length,
        }}>
        {this.props.children}
      </Context.Provider>
    );
  }
}
