import { Routes, Route } from 'react-router-dom';
import { useData } from '@bedrockio/pages/stores';

import Home from 'pages/Home';
import Login from 'pages/Login';
import Custom from 'pages/Custom';
import NotFound from 'pages/NotFound';

export default function Router(data) {
  const { pages = [] } = Object.keys(data || {}).length > 0 ? data : useData();
  return (
    <Routes>
      <Route path="/" element={<Home />} exact />
      <Route path="/login" element={<Login />} exact />
      {pages.map((page) => {
        const { path } = page;
        return <Route key={path} path={path} element={<Custom {...page} />} />;
      })}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
