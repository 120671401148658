import React from 'react';

import Trigger from './Trigger';
import Content from './Content';
import Context from './Context';

export default class Expandable extends React.Component {
  static contextType = Context;

  static Trigger = Trigger;
  static Content = Content;

  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  onTriggerClick = () => {
    this.setState({
      open: !this.state.open,
    });
  };

  render() {
    return (
      <Context.Provider
        value={{
          open: this.state.open,
          onTriggerClick: this.onTriggerClick,
        }}>
        <div {...this.props} onClick={this.onTriggerClick} />
      </Context.Provider>
    );
  }
}
