import w300 from './hero@300w.avif';
import w600 from './hero@600w.avif';
import w1200 from './hero@1200w.avif';

export default {
  ratio: 1.5,
  images: [
    {
      size: 300,
      url: w300,
    },
    {
      size: 600,
      url: w600,
    },
    {
      size: 1200,
      url: w1200,
    },
  ],
};