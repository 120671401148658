import React from 'react';

import { Field } from '@bedrockio/pages';
import { useData } from '@bedrockio/pages/stores';

export default function CustomPage(props) {
  const { getPageField } = useData();

  const { type, path } = props;
  if (type === 'article') {
    return (
      <React.Fragment>
        <Field name={getPageField(path, 'image')} image />
        <h1>
          <Field name={getPageField(path, 'title')} />
        </h1>
        <article>
          <Field name={getPageField(path, 'body')} text />
        </article>
      </React.Fragment>
    );
  } else if (type === 'legal') {
    return (
      <React.Fragment>
        <article>
          <Field name={getPageField(path, 'body')} text />
        </article>
      </React.Fragment>
    );
  }
}
