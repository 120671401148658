import { useClass } from '@bedrockio/pages/utils';

export default function MailLink(props) {
  const { mail, children, ...rest } = props;
  const href = `mailto:${mail}`;
  const { className } = useClass('mail-link');

  return (
    <a
      {...rest}
      href={href}
      target="_blank"
      className={className}
      rel="external noopener noreferrer">
      {children || mail}
    </a>
  );
}
