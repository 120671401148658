import React from 'react';
import { bem } from '@bedrockio/pages/utils';

import Context from './Context';

import './content.less';

@bem
export default class ExpandableContent extends React.Component {
  static contextType = Context;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  getModifiers() {
    const { open } = this.context;
    return [open ? 'open' : null];
  }

  getStyles() {
    const el = this.ref.current;
    const { open } = this.context;
    if (el && open) {
      return {
        height: `${el.scrollHeight}px`,
      };
    }
  }

  render() {
    return (
      <div
        {...this.props}
        ref={this.ref}
        style={this.getStyles()}
        className={this.getBlockClass()}
      />
    );
  }
}
