import { useRef, useMemo } from 'react';
import { useSpring, useSprings, animated } from '@react-spring/web';
import { Icon } from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';
import { useDrag } from '@use-gesture/react';

import './deck.less';

const SLIDING = {
  config: {
    friction: 50,
    tension: 200,
  },
};

const DRAGGING = {
  config: {
    friction: 50,
    tension: 800,
  },
};

function getInitial(i) {
  return {
    x: 0,
    y: i * 42,
    opacity: 1,
    scale: 1 - i * 0.08,
    userSelect: 'auto',
    ...SLIDING,
  };
}

export default function Deck(props) {
  const { children } = props;
  const { className, getElementClass } = useClass('deck');
  const handRef = useRef();
  const trackRef = useRef();

  const [cards, api] = useSprings(children.length, (i) => {
    return getInitial(i);
  });

  const data = useMemo(() => {
    return {
      current: 0,
    };
  }, []);

  function dragHand(dProps) {
    const { down, movement } = dProps;
    const track = trackRef.current;
    let [x] = movement;
    x = Math.min(x, track.clientWidth * 0.5);
    x = Math.max(x, track.clientWidth * -0.5);

    handApi.start(() => {
      if (!down) {
        return {
          x: 0,
        };
      } else {
        return {
          x,
        };
      }
    });
  }

  function dragCard(index, dProps, options = {}) {
    const { down, movement, velocity, direction } = dProps;
    const { constrain } = options;
    const [mx, my] = movement;

    api.start((i) => {
      if (i !== index) {
        return;
      }
      if (!down) {
        let dir;
        let out = false;

        if (velocity > 0.2) {
          // Flick
          out = true;
          dir = direction[0] < 0 ? -1 : 1;
        } else if (Math.abs(mx) > 150) {
          out = true;
          dir = mx < 0 ? -1 : 1;
        }

        if (out) {
          setTimeout(() => {
            if (i === cards.length - 1) {
              data.current = 0;
              api.start((j) => {
                return {
                  ...getInitial(j),
                  delay: j * 150,
                };
              });
            } else {
              data.current = i + 1;
              api.start((j) => {
                const newIndex = j - i - 1;
                if (newIndex >= 0) {
                  return {
                    ...getInitial(newIndex),
                    delay: newIndex * 200,
                  };
                }
              });
            }
          }, 500);
          return {
            x: (window.innerWidth + 200) * dir,
            ...SLIDING,
          };
        } else {
          return {
            x: 0,
            y: 0,
            ...SLIDING,
          };
        }
      } else {
        return {
          x: mx,
          y: constrain ? 0 : my,
          ...DRAGGING,
          userSelect: 'none',
        };
      }
    });
  }

  const drag = useDrag((dProps) => {
    const { args } = dProps;
    const [index] = args;

    dragHand(dProps);
    dragCard(index, dProps);
  });

  const [hand, handApi] = useSpring(() => {
    return {
      x: 0,
      config: {
        friction: 50,
        tension: 400,
      },
    };
  });

  const handDrag = useDrag((dProps) => {
    dragHand(dProps);
    dragCard(data.current, dProps, {
      constrain: true,
    });
  });

  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  // const drag = useDrag(({ args, down, movement, direction, velocity }) => {
  //   const [index] = args;
  //   const [mx, my] = movement;
  //   const [xDir] = direction;

  //   const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
  //   const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
  //   if (!down && trigger) {
  //     gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
  //     setTimeout(() => {
  //       // data.current = index + 1;
  //     }, 300);
  //   }
  //   console.info('GOING');
  //   api.start((i) => {
  //     if (index !== i) {
  //       const j = i - data.current;
  //       return {
  //         scale: 1,
  //       };
  //     }
  //     const isGone = gone.has(index);
  //     const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
  //     const y = my;
  //     const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
  //     const scale = down ? 1.04 : 1; // Active cards lift up a bit
  //     return {
  //       x,
  //       y,
  //       rot,
  //       scale,
  //       config: {
  //         friction: 50,
  //         tension: down ? 800 : isGone ? 200 : 500,
  //       },
  //     };
  //   });
  //   if (!down && gone.size === cards.length) {
  //     setTimeout(() => {
  //       gone.clear();
  //       api.start((i) => {
  //         return {
  //           x: 0,
  //           y: i * 22,
  //           scale: 1 - 0.05 * i,
  //           delay: i * 100,
  //         };
  //       });
  //     }, 600);
  //   }
  // });
  // Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
  return (
    <div className={className}>
      {/* {cards.map(({ x, y, scale }, i) => {
        const child = children[i];
        return (
          <animated.div
            key={i}
            style={{
              x,
              y,
              zIndex: cards.length - i,
            }}
            className={getElementClass('card')}>
            <animated.div
              {...drag(i)}
              style={{
                transform: interpolate([scale], (s) => {
                  return `scale(${s})`;
                }),
              }}>
              {child}
            </animated.div>
          </animated.div>
        );
      })} */}
      <div className={getElementClass('cards')}>
        {cards.map((spring, i) => {
          const element = children[i];
          return (
            <animated.div
              key={i}
              style={{
                zIndex: cards.length - i,
                ...spring,
              }}
              {...drag(i)}
              className={getElementClass('card')}>
              {element}
            </animated.div>
          );
        })}
      </div>
      <div className={getElementClass('slider')}>
        <animated.div
          ref={handRef}
          className={getElementClass('slider-thumb')}
          style={hand}
          {...handDrag()}>
          <Icon name="hand-drag" />
        </animated.div>

        <div className={getElementClass('slider-dot')} />
        <div ref={trackRef} className={getElementClass('slider-track')} />
        <div className={getElementClass('slider-dot')} />
      </div>
    </div>
  );
}
