import React from 'react';
import PropTypes from 'prop-types';
import { omit, clamp, debounce } from 'lodash';

import { bem } from '@bedrockio/pages/utils';

import IconButton from 'components/IconButton';

import './carousel.less';

@bem
export default class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      index: 0,
      lastVisible: false,
    };
  }

  componentDidMount() {
    const length = this.getLength();
    this.setState({
      length,
    });
    // this.checkLastVisible();
  }

  componentDidUpdate(lastProps, lastState) {
    const { index } = this.state;
    const { index: lastIndex } = lastState;
    if (index !== lastIndex) {
      this.focusItem(index);
    }
  }

  focusItem(index) {
    const el = this.ref.current;
    const child = el.children[index];

    if (child) {
      el.scrollTo(child.offsetLeft, 0);
    }
  }

  getLength() {
    return this.ref.current?.children.length;
  }

  onPrevClick = () => {
    if (!this.prevDisabled()) {
      this.move(-1);
    }
  };

  onNextClick = () => {
    if (!this.nextDisabled()) {
      this.move(1);
    }
  };

  prevDisabled = () => {
    return this.state.index === 0;
  };

  nextDisabled = () => {
    const { index, length, lastVisible } = this.state;
    const isEnd = index === length - 1;
    return isEnd || lastVisible;
  };

  move = (dir) => {
    const { index, length } = this.state;
    this.setState({
      index: clamp(index + dir, 0, length - 1),
    });
    // this.checkLastVisibleDeferred();
  };

  // checkLastVisible = () => {
  //   const el = this.ref.current;
  //   const last = el.children[el.children.length - 1];
  //   if (last) {
  //     const rect = last.getBoundingClientRect();
  //     this.setState({
  //       lastVisible: rect.right < window.innerWidth,
  //     });
  //   }
  // };

  // checkLastVisibleDeferred = debounce(this.checkLastVisible, 500);

  render() {
    const props = omit(this.props, Object.keys(Carousel.propTypes));
    return (
      <div className={this.getBlockClass()} {...props}>
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderButtons()}
        {this.renderArrows('mobile')}
      </div>
    );
  }

  renderHeader() {
    const { header } = this.props;
    if (header) {
      return (
        <div className={this.getElementClass('header')}>
          {header}
          {this.renderArrows('desktop')}
        </div>
      );
    }
  }

  renderContent() {
    return (
      <div className={this.getElementClass('body-container')}>
        {this.renderBody()}
      </div>
    );
  }

  renderBody() {
    const { children } = this.props;
    if (!children) {
      return;
    }
    const ref = this.ref;
    const className = this.getElementClass('body');

    if (Array.isArray(children)) {
      return (
        <div ref={this.ref} className={className}>
          {children}
        </div>
      );
    } else {
      return React.cloneElement(children, {
        ref,
        className,
      });
    }
  }

  renderArrows(area) {
    if (this.prevDisabled() && this.nextDisabled()) {
      return;
    }
    return (
      <div className={this.getElementClass('arrows', area)}>
        <div
          className={this.getElementClass(
            'arrow',
            this.prevDisabled() ? 'disabled' : null,
          )}>
          <IconButton name="arrow-left" onClick={this.onPrevClick} />
        </div>
        <div
          className={this.getElementClass(
            'arrow',
            this.nextDisabled() ? 'disabled' : null,
          )}>
          <IconButton name="arrow-right" onClick={this.onNextClick} />
        </div>
      </div>
    );
  }

  renderButtons() {
    const { style } = this.props;
    if (style !== 'buttons') {
      return;
    }
    const { index, length } = this.state;
    if (length == null) {
      return;
    }

    const buttons = Array.from(new Array(length), (el, i) => {
      const current = index === i;
      return (
        <div
          key={i}
          className={this.getElementClass('button', current ? 'current' : null)}
          onClick={() => {
            this.setState({
              index: i,
            });
          }}
        />
      );
    });

    return <div className={this.getElementClass('buttons')}>{buttons}</div>;
  }
}

Carousel.propTypes = {
  header: PropTypes.node,
  style: PropTypes.string,
};

Carousel.defaultProps = {
  style: 'arrows',
};
