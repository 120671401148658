import React, { useState, useEffect } from 'react';

import { Icon } from '@bedrockio/pages';
import { useClass } from '@bedrockio/pages/utils';

import './drawer.less';

export default function Drawer(props) {
  const { trigger, children } = props;

  const [open, setOpen] = useState(props.open || false);

  const { className, getElementClass } = useClass(
    'drawer',
    open ? 'open' : null,
  );

  function toggle() {
    setOpen(!open);
  }

  function close() {
    setOpen(false);
  }

  useEffect(() => {
    setOpen(!!props.open);
  }, [props.open]);

  useEffect(() => {
    if (props.setOpen) {
      props.setOpen(open);
    }
    document.documentElement.classList.toggle('fixed', open);
  }, [open]);

  return (
    <React.Fragment>
      <div className={getElementClass('trigger')} onClick={toggle}>
        {trigger}
      </div>
      <div className={className}>
        <div className={getElementClass('content')}>
          <Icon
            name="close"
            onClick={close}
            className={getElementClass('close')}
          />
          {children}
        </div>
        <div className={getElementClass('backdrop')} onClick={close} />
      </div>
    </React.Fragment>
  );
}
