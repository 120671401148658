import { useClass } from '@bedrockio/pages/utils';

import { APP_URL } from 'utils/env';

import './app-link.less';

export default function AppLink(props) {
  const { to = '', className: addClass = '' } = props;
  const url = `${APP_URL}${to}`;
  const { className } = useClass(`app-link ${addClass}`);
  return (
    <a
      {...props}
      href={url}
      target="_blank"
      className={className}
      rel="external noopener noreferrer"
    />
  );
}
