import React from 'react';
import { round, clamp } from 'lodash';
import { bem } from '@bedrockio/pages/utils';

import ScrollObserver from 'utils/ScrollObserver';
import { mapLinear } from 'utils/math';

import './color-shift.less';

@bem
export default class ColorShift extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      opacity: 0,
    };
  }

  componentDidMount() {
    const { current: el } = this.ref;
    if (el) {
      this.observer = new ScrollObserver(el, this.onElementScroll);
    }
  }

  componentWillUnmount() {
    this.observer?.destroy();
  }

  onElementScroll = (rect) => {
    const top = window.innerHeight / 6;
    const bottom = window.innerHeight;
    const mid = rect.top + rect.height / 2;
    let opacity = mapLinear(mid, top, bottom, 1, 0);
    opacity = round(opacity, 2);
    opacity = clamp(opacity, 0, 1);

    this.setState({
      opacity,
    });
  };

  getStyles() {
    const { opacity } = this.state;

    return {
      '--opacity': opacity,
    };
  }

  render() {
    const { to, from, ...rest } = this.props;
    return (
      <div
        {...rest}
        ref={this.ref}
        style={this.getStyles()}
        className={this.getBlockClass()}
      />
    );
  }
}
