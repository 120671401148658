import React from 'react';
import { Icon } from '@bedrockio/pages';
import { bem } from '@bedrockio/pages/utils';

import './icon-button.less';

@bem
export default class IconButton extends React.Component {
  getModifiers() {
    const { disabled } = this.props;
    return [disabled ? 'disabled' : null];
  }

  render() {
    const { name, ...rest } = this.props;
    return (
      <div className={this.getBlockClass()} {...rest}>
        <Icon name={name} />
      </div>
    );
  }
}
