import React from 'react';
import { bem } from '@bedrockio/pages/utils';

import './jump-link.less';

@bem
export default class JumpLink extends React.Component {
  onClick = () => {
    const { id } = this.props;
    const el = document.getElementById(id);
    el.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
    this.props.onClick?.();
  };

  render() {
    const { id, ...rest } = this.props;
    return (
      <div {...rest} onClick={this.onClick} className={this.getBlockClass()} />
    );
  }
}
