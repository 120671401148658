import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from '@bedrockio/pages';
import { bem } from '@bedrockio/pages/utils';
import { omit } from 'lodash';

import './button.less';

@bem
export default class Button extends React.Component {
  getModifiers() {
    const { primary, tight } = this.props;
    return [primary ? 'primary' : null, tight ? 'tight' : null];
  }

  getStyles() {
    const { color } = this.props;
    if (color) {
      return {
        '--color': color,
      };
    }
  }

  render() {
    const props = omit(this.props, Object.keys(Button.propTypes));
    const { children } = this.props;
    return (
      <button
        {...props}
        style={this.getStyles()}
        className={this.getBlockClass()}>
        {this.renderIcon()}
        {children}
      </button>
    );
  }

  renderIcon() {
    const { icon } = this.props;
    if (icon) {
      return <Icon size="1em" name={icon} />;
    }
  }
}

Button.propTypes = {
  primary: PropTypes.bool,
  color: PropTypes.string,
  tight: PropTypes.bool,
  icon: PropTypes.node,
};
