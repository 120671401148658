import { useClass } from '@bedrockio/pages/utils';

import { APP_URL } from 'utils/env';

import './app-button.less';

export default function AppButton(props) {
  const { to = '', primary, round, ...rest } = props;
  const url = `${APP_URL}${to}`;
  const { className } = useClass(
    'app-button',
    primary ? 'primary' : null,
    round ? 'round' : null,
  );

  function onClick(evt) {
    if (props.onClick) {
      evt.preventDefault();
      props.onClick();
    }
  }

  return (
    <a
      {...rest}
      href={url}
      target="_blank"
      className={className}
      onClick={onClick}
      rel="external noopener noreferrer"
    />
  );
}
