import { useState } from 'react';
import { Link } from 'react-router-dom';

import { Icon, ExternalLink } from '@bedrockio/pages';
import { useData } from '@bedrockio/pages/stores';
import { useClass } from '@bedrockio/pages/utils';

import AppLink from 'components/AppLink';

import Drawer from 'components/Drawer';
import JumpLink from 'components/JumpLink';
import AppButton from 'components/AppButton';
import logo from 'assets/images/logo.svg';

import './header.less';

export default function Header() {
  const { pages } = useData();
  const { className, getElementClass } = useClass('header');
  const [open, setOpen] = useState(false);

  function close() {
    setOpen(false);
  }

  return (
    <header className={className}>
      <Drawer open={open} setOpen={setOpen} trigger={<Icon name="menu" />}>
        <div className={getElementClass('nav-container')}>
          <nav className={getElementClass('nav')}>
            <Link to="/">Home</Link>
            <JumpLink id="discover-rove" onClick={close}>
              Discover Rove
            </JumpLink>
            <JumpLink id="how-it-works" onClick={close}>
              How it Works
            </JumpLink>
            <JumpLink id="our-expertise" onClick={close}>
              Our Expertise
            </JumpLink>
            <JumpLink id="real-rove-stories" onClick={close}>
              Real Rove Stories
            </JumpLink>
            <JumpLink id="ai-powered-app" onClick={close}>
              Rove AI-Powered App
            </JumpLink>
            <JumpLink id="meet-our-team" onClick={close}>
              Meet our Team
            </JumpLink>
            <JumpLink id="faq" onClick={close}>
              FAQ
            </JumpLink>
            {pages
              .filter((page) => {
                return page.nav;
              })
              .map((page) => {
                const { path, name } = page;
                return (
                  <Link key={path} to={path}>
                    {name}
                  </Link>
                );
              })}
          </nav>
          <div className={getElementClass('buttons')}>
            <AppButton to="/register" primary>
              Get Started
            </AppButton>
            <AppButton to="/login">Sign In</AppButton>
          </div>
          <hr />
          <div className={getElementClass('bottom')}>
            <div className={getElementClass('social')}>
              <ExternalLink
                href="https://www.linkedin.com/company/rove-nutrition"
                className={getElementClass('social-button')}>
                <Icon name="linkedin" />
              </ExternalLink>
              <ExternalLink
                href="https://www.instagram.com/rovenutrition/"
                className={getElementClass('social-button')}>
                <Icon name="instagram" />
              </ExternalLink>
            </div>
          </div>
        </div>
      </Drawer>
      <div className={getElementClass('logo')}>
        <Link to="/">
          <img src={logo} width="100" height="30" />
        </Link>
      </div>
      <div className={getElementClass('right')}>
        <AppLink to="/login">Sign in</AppLink>
        <AppButton to="/register" primary round>
          Get Started
        </AppButton>
      </div>
    </header>
  );
}
